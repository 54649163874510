import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import { token } from '@atlaskit/tokens';

import { fg } from '@confluence/feature-gating';
import { ViewAttachmentsButton } from '@confluence/attachments';
import { DraftStatus, ScheduledStatus } from '@confluence/content-metadata';
import { Attribution, ErrorBoundary } from '@confluence/error-boundary';
import { JiraButtonAnalyticsWrapper } from '@confluence/jira-metadata';
import { RestrictionsDialogTrigger } from '@confluence/restrictions';
import { ShareButton } from '@confluence/share';
import { ShareAndRestrictButton } from '@confluence/share-and-restrict-dialog';
import {
	WebItem,
	INVITE_TO_EDIT_ID,
	DRAFT_STATUS_LOZENGE_ID,
	CONTENT_METADATA_JIRA_ID,
	ANALYTICS_ADDON_BYLINE_ID,
	SCHEDULED_STATUS_LOZENGE_ID,
	ANALYTICS_ADDON_ACTIONS_MENU_ID,
	CONTENT_METADATA_ATTACHMENTS_ID,
	CONTENT_METADATA_PAGE_RESTRICTIONS_ID,
} from '@confluence/web-item';
import { useUnifiedShareDialogEligible } from '@confluence/experiment-unified-share-dialog';

export const SHARE_BTN_STYLE = {
	font: token('font.body'),
	display: 'inline-block',
	verticalAlign: 'top',
	paddingLeft: token('space.100'),
};

export const WebItemList = (props) => {
	const {
		webItems,
		actions,
		modifications,
		className,
		id,
		disableIcons,
		webItemClassName,
		ContainerTagName,
		pageId,
		dataTestId,
		contentSubType,
		hideDot,
		source,
		noContainer = false,
		useBadgeStyles = false,
	} = props;
	const { isUSDExperimentEnabled } = useUnifiedShareDialogEligible(true);

	const renderWebItems = () =>
		webItems.map((webItem) => {
			const webItemProps = {
				key: webItem.completeKey,
				url: webItem.url,
				id: webItem.id,
				onClick: webItem.onClick || actions[webItem.moduleKey],
				className: classnames(webItem.styleClass, webItemClassName, {
					'with-dot':
						// For live pages, hideDot will be true and we don't want to show dot separator
						!hideDot,
				}),
				params: webItem.params,
				iconInfo: webItem.icon,
				label: webItem.label,
				tooltip: webItem.tooltip,
				spinner: modifications[webItem.moduleKey]
					? modifications[webItem.moduleKey].spinner
					: false,
				disableIcon: disableIcons,
				iconStyle: webItem.iconStyle,
				addLargerMargin: hideDot,
				useBylineStyles: hideDot,
			};

			// Wow! The JIRA metadata item needs this data prop to work correctly! That was an easy debug ha hoo
			if (webItem.id === CONTENT_METADATA_JIRA_ID) {
				webItemProps['data-jira-metadata-count'] = -1;
			}

			// "Unpublished Changes" lozenge
			if (pageId && webItem.id === DRAFT_STATUS_LOZENGE_ID) {
				if (webItem.moduleKey === SCHEDULED_STATUS_LOZENGE_ID) {
					// Only show "Scheduled publish" lozenge
					return <ScheduledStatus key={pageId} contentId={pageId} />;
				} else {
					return <DraftStatus key={pageId} contentId={pageId} />;
				}
			}

			// TODO(brizvash): move the button to header, no need to check it all the time
			if (webItem.id === CONTENT_METADATA_ATTACHMENTS_ID) {
				return <ViewAttachmentsButton key="view-attachments-button" />;
			}

			if (webItem.id === INVITE_TO_EDIT_ID) {
				return (
                    // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
                    <span style={SHARE_BTN_STYLE} key="fabric-share">
                        {fg('confluence_frontend_unified_restrict_and_share') || isUSDExperimentEnabled ? (
							<ErrorBoundary
								attribution={Attribution.PERMISSIONS_EXPERIENCE}
								key="share-and-restrict-button"
							>
								<ShareAndRestrictButton
									contentId={webItem.contentId}
									contentType={webItem.contentType ?? 'page'}
									contentSubType={contentSubType || undefined}
									source={source}
								/>
							</ErrorBoundary>
						) : (
							<ShareButton
								contentId={webItem.contentId}
								contentSubType={contentSubType || undefined}
							/>
						)}
                    </span>
                );
			}

			if (webItem.id === CONTENT_METADATA_PAGE_RESTRICTIONS_ID) {
				// Dialog is already attached to view page level
				return fg('confluence_frontend_unified_restrict_and_share') ||
					isUSDExperimentEnabled ? null : (
					<ErrorBoundary
						attribution={Attribution.PERMISSIONS_EXPERIENCE}
						key="restrictions-dialog-trigger"
					>
						<RestrictionsDialogTrigger
							contentId={webItem.contentId}
							triggeredFrom="fromView"
							contentSubType={contentSubType}
						/>
					</ErrorBoundary>
				);
			}

			if (
				webItem.id === ANALYTICS_ADDON_ACTIONS_MENU_ID ||
				webItem.id === ANALYTICS_ADDON_BYLINE_ID
			) {
				return <></>;
			}

			if (webItem.id === CONTENT_METADATA_JIRA_ID) {
				return (
					<JiraButtonAnalyticsWrapper key={webItem.completeKey}>
						<WebItem {...webItemProps} />
					</JiraButtonAnalyticsWrapper>
				);
			}

			return (
				<WebItem key={webItem.completeKey} {...webItemProps} useBadgeStyles={useBadgeStyles} />
			);
		});

	if (noContainer) {
		return <>{renderWebItems()}</>;
	}

	return (
		<ContainerTagName
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
			className={className}
			id={id}
			data-test-id={dataTestId} // editor button tests use this, do not remove
			data-testid={dataTestId || 'web-item-wrapper'}
		>
			{renderWebItems()}
		</ContainerTagName>
	);
};

WebItemList.displayName = 'WebItemList';
WebItemList.defaultProps = {
	disableIcons: false,
	ContainerTagName: 'div',
	actions: {},
	modifications: {},
	noContainer: false,
	useBadgeStyles: false,
};
WebItemList.propTypes = {
	webItems: PropTypes.array,
	actions: PropTypes.object,
	modifications: PropTypes.object,
	className: PropTypes.string,
	id: PropTypes.string,
	disableIcons: PropTypes.bool,
	ContainerTagName: PropTypes.string,
	webItemClassName: PropTypes.string,
	pageId: PropTypes.string,
	dataTestId: PropTypes.string,
	contentSubType: PropTypes.string,
	hideDot: PropTypes.bool,
	source: PropTypes.string,
	noContainer: PropTypes.bool,
	useBadgeStyles: PropTypes.bool,
};
